import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import "./AnalysisDashboard.css";
import { useState } from "react";
import httpClient from "../../../services/axios";
import { handleErrorResponse } from "../../../helpers/responseHandler";
import LoaderSpinner from "../../../components/general/loader/loader.spinner";
import { createNotification } from "../../../helpers/createNotifications";

const WarningsOverview = ({ ClientSlug }) => {
  const [warningData, setWarningData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchWarningsData = async () => {
    if (ClientSlug) {
      setIsLoading(true);
      const queryParams = {
        user_id: ClientSlug,
      };
      const queryString = new URLSearchParams(queryParams).toString();
      try {
        const res = await httpClient.get(
          `api/v1/goals/client_goals_warnings/?${queryString}`
        );
        if (res?.data) {
          setWarningData(res.data?.data);
        } else {
          createNotification("error", "Unable to fetch insight data");
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        handleErrorResponse(error);
      }
    }
  };

  useEffect(() => {
    fetchWarningsData();
  }, [ClientSlug]);

  if (isLoading) {
    return (
      <LoaderSpinner
        tip="Loading"
        size="50"
        top={"50%"}
        left={"39%"}
        color="#f6891f"
      />
    );
  }

  return (
    <div className="warning-sections d-flex justify-content-between gap-20">
      <div className="time-exceeded-goals">
        <label className="lm-input-form-input-label mb-5">
          Goals with Exceeded time limit
        </label>
        <div className="warning-goals-box">
          <div className="warning-goals-tag-container">
            {warningData?.limit_exceeded_goals?.map((record) => (
              <span key={record?.id} className="warning-goals-tag">
                {record?.goal_name}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className="not-tested-goals">
        <label className="lm-input-form-input-label mb-5">
          Goals not yet tested
        </label>
        <div className="warning-goals-box">
          <div className="warning-goals-tag-container">
            {warningData?.testing_req_goals?.map((record) => (
              <span key={record?.id} className="warning-goals-tag">
                {record?.goal_name}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarningsOverview;
