import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import "./AnalysisDashboard.css";
import { useState } from "react";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import httpClient from "../../../services/axios";
import { handleErrorResponse } from "../../../helpers/responseHandler";
import LoaderSpinner from "../../../components/general/loader/loader.spinner";
import { createNotification } from "../../../helpers/createNotifications";

const InsightsOverview = ({ ClientSlug }) => {
  const [insightData, setInsightData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchInsightData = async () => {
    if (ClientSlug) {
      setIsLoading(true);
      const queryParams = {
        user_id: ClientSlug,
      };
      const queryString = new URLSearchParams(queryParams).toString();
      try {
        const res = await httpClient.get(
          `api/v1/goals/client_goals_insight/?${queryString}`
        );
        if (res?.data) {
          setInsightData(res.data?.data);
        } else {
          createNotification("error", "Unable to fetch insight data");
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        handleErrorResponse(error);
      }
    }
  };

  useEffect(() => {
    fetchInsightData();
  }, [ClientSlug]);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, time, amt } = payload[0].payload;
      return (
        <div className="custom-tooltip">
          <p className="label">
            <span style={{ fontWeight: "bold" }}>Goal Title:</span> {name}
          </p>
          <p className="label">
            <span style={{ fontWeight: "bold" }}>Mastery Time:</span> {time}
          </p>
          <p className="label">
            <span style={{ fontWeight: "bold" }}>Tech Names:</span>{" "}
            {amt.join(", ")}
          </p>
        </div>
      );
    }
    return null;
  };

  if (isLoading) {
    return (
      <LoaderSpinner
        tip="Loading"
        size="50"
        top={"50%"}
        left={"39%"}
        color="#f6891f"
      />
    );
  }
  return (
    <div className="insight-overview" style={{ width: "100%", height: 300 }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={insightData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 15,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} /> {/* Custom Tooltip */}
          <Legend />
          <Bar dataKey="time" fill="#B3CDAD" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default InsightsOverview;
