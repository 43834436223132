import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import "./AnalysisDashboard.css";
import { useState } from "react";
import WarningsOverview from "./WarningsOverview";
import { Button } from "react-bootstrap";
import InsightsOverview from "./InsightsOverview";

const InsightsAndWarnings = ({ ClientSlug }) => {
  const [insightWarningView, setInsightWarningView] = useState("insights");

  useEffect(() => {
    setInsightWarningView("insights");
  }, [ClientSlug]);

  return (
    <div className="insights-warnings">
      <div className="insight-warning-filters d-flex justify-content-between align-items-center">
        <Button
          autoFocus={true} // This Btn will be focused at first time
          className="insight-btn"
          onClick={() => {
            setInsightWarningView("insights");
          }}
        >
          Insights
        </Button>

        <div className="">
          <h4>Insights And Warnings</h4>
        </div>
        <Button
          className="warning-btn "
          onClick={() => {
            setInsightWarningView("warnings");
          }}
        >
          Warnings
        </Button>
      </div>
      <div className="warning-and-insights-sections">
        {insightWarningView === "insights" ? (
          <InsightsOverview ClientSlug={ClientSlug} />
        ) : (
          <WarningsOverview ClientSlug={ClientSlug} />
        )}
      </div>
    </div>
  );
};

export default InsightsAndWarnings;
