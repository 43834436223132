import "bootstrap/dist/css/bootstrap.min.css";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import LinearGraph from "../../../components/Graphs/LinearGraph";
import DatePickerLM from "../../../components/form-controls/input/DatePicker/DatePickerLM";
import { handleErrorResponse } from "../../../helpers/responseHandler";
import httpClient from "../../../services/axios";
import "./AnalysisDashboard.css";
import { createNotification } from "../../../helpers/createNotifications";
import moment from "moment";
import LoaderSpinner from "../../../components/general/loader/loader.spinner";

const TimeScoreGraph = ({ ClientSlug }) => {
  const [selectedGoals, setSelectedGoals] = useState([]);
  const [filterParams, setFilterParams] = useState();
  const [clientGoals, setClientGoals] = useState([]);
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    setSelectedGoals([]);
    setGraphData([]);
  }, [ClientSlug]);

  const getDefaultDates = () => {
    const currentDate = moment();

    const startDate = currentDate.clone().subtract(1, "week").startOf("week");
    const endDate = currentDate.clone().subtract(1, "week").endOf("week");

    const formattedStartDate = startDate.format("YYYY-MM-DD");
    const formattedEndDate = endDate.format("YYYY-MM-DD");

    return {
      defaultStartDate: formattedStartDate,
      defaultEndDate: formattedEndDate,
    };
  };

  const { defaultStartDate, defaultEndDate } = getDefaultDates();

  useEffect(() => {
    const fetchClientGoals = async () => {
      const queryParams = {
        page: 1,
        per_page: 400, // TODOS In the future, we will add search option
        user_id: ClientSlug,
        state: "un_archived",
      };
      const queryString = new URLSearchParams(queryParams).toString();
      try {
        const res = await httpClient.get(
          `/api/v1/goals/client_goals?${queryString}`
        );
        if (res?.data) {
          setClientGoals(res.data?.goals);
        } else {
          createNotification("error", "Unable to fetch Client Goals");
        }
      } catch (error) {
        handleErrorResponse(error);
      }
    };
    if (ClientSlug) {
      fetchClientGoals();
    }
  }, [ClientSlug]);

  const handleGoalsChange = (selectedGoals) => {
    setSelectedGoals(selectedGoals);
    const goalIds = selectedGoals.map((option) => option.value);
    setFilterParams((prev) => ({
      ...prev,
      goal_ids: goalIds,
    }));
  };

  const allFiltersPresent = () => {
    const { start_date, end_date, goal_ids } = filterParams || {};
    if (
      ((!!start_date && !!end_date) || (defaultStartDate && defaultEndDate)) &&
      Array.isArray(goal_ids) &&
      goal_ids.length > 0
    ) {
      return true;
    } else {
      setGraphData([]);
      return false;
    }
  };

  useEffect(() => {
    const goalTimeScoreGraph = async () => {
      const queryParams = {
        user_id: ClientSlug,
        start_date: filterParams.start_date || defaultStartDate,
        end_date: filterParams.end_date || defaultEndDate,
      };
      const queryString = new URLSearchParams(queryParams).toString();
      try {
        const res = await httpClient.get(
          `/api/v1/goals/goal_time_score_graph?${queryString}`,
          {
            params: {
              goal_ids: filterParams.goal_ids,
            },
          }
        );
        if (res?.data) {
          setGraphData(res.data?.graph_data);
        } else {
          createNotification("error", "Unable to fetch Graph Data");
        }
      } catch (error) {
        handleErrorResponse(error);
      }
    };
    if (allFiltersPresent()) {
      goalTimeScoreGraph();
    }
  }, [filterParams]);

  return (
    <div className="time-score-graph">
      <div className="text-center mb-15">
        <h4>Time Score Graph</h4>
      </div>
      <div className="mb-10 time-score-graph-filters">
        <DatePickerLM
          label={"Start Date"}
          defaultValue={defaultStartDate}
          onChange={(date) => {
            const formattedDate = date
              ? dayjs(date).format("YYYY-MM-DD")
              : null;
            setFilterParams({ ...filterParams, start_date: formattedDate });
          }}
        ></DatePickerLM>

        <DatePickerLM
          label={"End Date"}
          defaultValue={defaultEndDate}
          onChange={(date) => {
            const formattedDate = date
              ? dayjs(date).format("YYYY-MM-DD")
              : null;
            setFilterParams({ ...filterParams, end_date: formattedDate });
          }}
        ></DatePickerLM>
        <div className="d-flex flex-column gap-2 flex-grow-1">
          <label className="lm-input-form-input-label">Select Goals</label>
          <div className="multi-selector">
            <Select
              isMulti
              options={clientGoals.map((goal) => ({
                label: goal.title,
                value: goal.id,
              }))}
              value={selectedGoals}
              onChange={handleGoalsChange}
              placeholder="Select Goals"
              className="w-100"
              isDisabled={!ClientSlug}
            />
          </div>
        </div>
      </div>
      <div className="mt-30">
        <LinearGraph graphData={graphData}></LinearGraph>
      </div>
    </div>
  );
};

export default TimeScoreGraph;
