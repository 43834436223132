import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { name, time, score, title, level } = payload[0].payload;
    return (
      <div className="custom-tooltip">
        <p className="label name-label">
          <strong>Title:</strong> {title}
        </p>
        <p className="label title-label">
          <strong>Refer:</strong> {name}
        </p>
        <p className="label time-label">
          <strong>Time:</strong> {`${time} hrs`}
        </p>
        <p className="label score-label">
          <strong>Score:</strong> {`${score} %`}
        </p>
        <p className="label score-label">
          <strong>Level:</strong> {`${level}`}
        </p>
      </div>
    );
  }
  return null;
};

const LinearGraph = ({ graphData }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart width={300} height={300} data={graphData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        {/* <Tooltip /> */}
        <Legend />
        <Line
          type="monotone"
          dataKey="time"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
          strokeWidth={2}
        />
        <Line
          type="monotone"
          dataKey="score"
          stroke="#82ca9d"
          strokeWidth={2}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LinearGraph;
