import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import "./AnalysisDashboard.css";
import { Button } from "react-bootstrap";
import TableView from "../../../components/TableViews/TableView";
import { handleErrorResponse } from "../../../helpers/responseHandler";
import httpClient from "../../../services/axios";
import LoaderSpinner from "../../../components/general/loader/loader.spinner";
import fetchFormattedDateTime from "../../../utils/datetimeUtlis";
import { createNotification } from "../../../helpers/createNotifications";

const ProgressReport = ({ tabOf, setdashboardView, ClientSlug }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [goalViewType, setGoalviewType] = useState("ProgressReportAssigned");
  const [progressData, setProgressData] = useState({});

  const fetchReportData = async ({ goalType }) => {
    if (ClientSlug) {
      setIsLoading(true);
      const queryParams = {
        user_id: ClientSlug,
        current_date: fetchFormattedDateTime(),
        goal_type: goalType,
        page: currentPage,
        per_page: 4,
      };
      const queryString = new URLSearchParams(queryParams).toString();
      try {
        const res = await httpClient.get(
          `api/v1/goals/goals_progress_report/?${queryString}`
        );
        if (res?.data) {
          setProgressData(res.data);
        } else {
          createNotification("error", "Unable to Generate Progress Report");
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        handleErrorResponse(error);
      }
    }
  };

  useEffect(() => {
    const goalTypeMaps = {
      ProgressReportAssigned: "assigned",
      ProgressReportInProgress: "in_progress",
      ProgressReportMastered: "mastered",
    };
    const selectedType = goalTypeMaps[goalViewType] || "";
    fetchReportData({ goalType: selectedType });
  }, [currentPage, ClientSlug]);

  useEffect(() => {
    setCurrentPage(1);
  }, [ClientSlug, goalViewType]);

  if (isLoading) {
    return (
      <LoaderSpinner
        tip="Loading"
        size="50"
        top={"50%"}
        left={"39%"}
        color="#f6891f"
      />
    );
  }
  return (
    <div className="progress-report">
      <div className="text-center mb-15">
        <h4>Progress Report</h4>
      </div>
      <div className="progress-report-filters text-center">
        <Button
          className="assigned-btn"
          onClick={() => {
            setGoalviewType("ProgressReportAssigned");
            fetchReportData({ goalType: "assigned" });
          }}
        >
          Assigned
        </Button>
        <Button
          className="in-progress-btn"
          onClick={() => {
            setGoalviewType("ProgressReportInProgress");
            fetchReportData({ goalType: "in_progress" });
          }}
        >
          In-Progress
        </Button>
        <Button
          className="mastered-btn"
          onClick={() => {
            setGoalviewType("ProgressReportMastered");
            fetchReportData({ goalType: "mastered" });
          }}
        >
          Mastered
        </Button>
      </div>

      <div className="">
        <TableView
          setdashboardView={setdashboardView}
          Pagination={true}
          View={goalViewType}
          tableData={progressData?.data}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          tabOf={tabOf}
          loading={isLoading}
          // actionDone={actionDone}
          // setActionDone={setActionDone}
          metaData={progressData?.meta}
          // setBillingData={setBillingData}
        />
      </div>
    </div>
  );
};

export default ProgressReport;
